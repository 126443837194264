:root {
	--gradient-shadow: linear-gradient(45deg,
			#fb0094,
			#0000ff,
			#00ff00,
			#ffff00,
			#ff0000,
			#fb0094,
			#0000ff,
			#00ff00,
			#ffff00,
			#ff0000);
}

.colored-bottom-line {
    background: rgba(255, 255, 255, 0);
    border-radius: 5px;
    /* box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); */
    /* border-bottom-color: aqua; */
    border-bottom-width: 2px;
    border-image: linear-gradient(80deg, #FCEE0C 20%, #000 20% 25%, #ff5bf8 25%) 100% 1;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    /* border: 1px solid rgba(255, 255, 255, 0.3); */
    height: min-content;
	width: max-content;
}

.color-changing-background {
	background: white;
	animation: colorchange 20s infinite alternate;
	-webkit-animation: colorchange 20s infinite alternate;
}

@keyframes colorchange {
	0% {
		color: #000000;
		background: #FCEE0C;
	}

	10% {
		color: white;
		background: #000000;
	}

	20% {

		background: #03D8F3;
	}

	30% {
		color: black;
		background: #ffffff;
	}

	40% {

		background: #772289;
	}

	50% {
		color: white;
		background: #000000;
	}

	60% {

		background: #00f0ff;
	}

	70% {
		color: black;
		background: #ffffff;
	}

	80% {

		background: #ff2b2b;
	}

	90% {
		color: white;
		background: #000000;
	}

	100% {

		background: #ff5bf8;
	}
}

.multicolortext {
	/* background: linear-gradient(to left, violet, indigo, green, blue, yellow, orange, red); */
	background: linear-gradient(80deg, #FCEE0C 20%, #ff5bf8 20%) 100%;
	-webkit-background-clip: text;
	-moz-background-clip: text;
	background-clip: text;
	color: transparent;
  }

.loading-overlay {
	font-family: 'Bebas Neue', cursive;
	font-size: 46px;
	z-index: 50;
	position: relative;
	align-self: center;
	color: white;
	width: 40vw;
	text-align: center;
	height: 100%;
}

.shadow {
	display: flex;
	justify-content: center;
	align-items: center;
	background: linear-gradient(0deg, #000, #262626);
	position: absolute;
	left: -1px;
	width: 40vw;
	height: 40vh;
	border-radius: 50px;
	perspective: 1px;
}

.shadow:before,
.shadow:after {
	content: "";
	position: absolute;
	/* top: -2px; */
	left: 0px;
	background: linear-gradient(70deg, #FCEE0C, #000, #ff5bf8, #FCEE0C, #000, #ff5bf8,  #FCEE0C, #000, #ff5bf8, #FCEE0C, #000);
	/* background: linear-gradient(70deg, #FCEE0C, #000, #03D8F3, #4bff21, #FCEE0C, #000,  #772289, #f8e602, #00f0ff, #ff2b2b, #ffff00, #000); */
	/* background: linear-gradient(45deg, #fb0094, #0000ff, #00ff00, #ffff00, #ff0000, #fb0094, #0000ff, #00ff00, #ffff00, #ff0000); */
	background-size: 350%;
	width: 100%;
	height: 100%;
	animation: shadow-animate 20s linear infinite;
	border-radius: 20px;
}

.shadow:after {
	filter: blur(20px);
}

@keyframes shadow-animate {
	0% {
		background-position: 0 0;
	}

	50% {
		background-position: 300% 0;
	}

	100% {
		background-position: 0 0;
	}
}

/* .cyberpunk-button,
.cyberpunk-button:after {
    padding-left: 10px;
    padding-right: 10px;
    height: 36px;
    font-size: 24px;
    font-family: 'Bebas Neue', cursive;
    background: linear-gradient(45deg, transparent 5%, #FF013C 5%);
    border: 0;
    color: #ffffff;
    letter-spacing: 3px;
    line-height: 88px;
    box-shadow: 6px 0px 0px #00E6F6;
    outline: transparent;
    position: relative;
    display: flex;
    align-items: center;
}

.cyberpunk-button:after {
    --slice-0: inset(50% 50% 50% 50%);
    --slice-1: inset(80% -6px 0 0);
    --slice-2: inset(50% -6px 30% 0);
    --slice-3: inset(10% -6px 85% 0);
    --slice-4: inset(40% -6px 43% 0);
    --slice-5: inset(80% -6px 5% 0);
    content: "GO THERE";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, transparent 3%, #00E6F6 3%, #00E6F6 5%, #FF013C 5%);
    text-shadow: -3px -3px 0px #F8F005, 3px 3px 0px #00E6F6;
    clip-path: var(--slice-0);
}

.cyberpunk-button:hover::after {
    animation: 1s glitch;
    animation-timing-function: steps(2, end);
} */

@keyframes glitch {
    0% {
        clip-path: var(--slice-1);
        transform: translate(-20px, -10px);
    }

    10% {
        clip-path: var(--slice-3);
        transform: translate(10px, 10px);
    }

    20% {
        clip-path: var(--slice-1);
        transform: translate(-10px, 10px);
    }

    30% {
        clip-path: var(--slice-3);
        transform: translate(0px, 5px);
    }

    40% {
        clip-path: var(--slice-2);
        transform: translate(-5px, 0px);
    }

    50% {
        clip-path: var(--slice-3);
        transform: translate(5px, 0px);
    }

    60% {
        clip-path: var(--slice-4);
        transform: translate(5px, 10px);
    }

    70% {
        clip-path: var(--slice-2);
        transform: translate(-10px, 10px);
    }

    80% {
        clip-path: var(--slice-5);
        transform: translate(20px, -10px);
    }

    90% {
        clip-path: var(--slice-1);
        transform: translate(-10px, 0px);
    }

    100% {
        clip-path: var(--slice-1);
        transform: translate(-20px, -10px);
    }
}